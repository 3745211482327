exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bipocpost-1820-js": () => import("./../../../src/pages/bipocpost1820.js" /* webpackChunkName: "component---src-pages-bipocpost-1820-js" */),
  "component---src-pages-bipocpre-1820-js": () => import("./../../../src/pages/bipocpre1820.js" /* webpackChunkName: "component---src-pages-bipocpre-1820-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nonbipocpost-1820-js": () => import("./../../../src/pages/nonbipocpost1820.js" /* webpackChunkName: "component---src-pages-nonbipocpost-1820-js" */),
  "component---src-pages-nonbipocpre-1820-js": () => import("./../../../src/pages/nonbipocpre1820.js" /* webpackChunkName: "component---src-pages-nonbipocpre-1820-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

